import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AccessInfoComponent } from "./access-info/access-info.component";
import { AuthorizationGuard } from "./core/auth/auth-guard.service";
import { UnauthorizedGuard } from "./core/auth/unauthorized-guard.service";
import { ManualComponent } from "./manual/manual.component";
import { translations } from "./config/translations/translations";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "manual",
    pathMatch: "full",
  },
  {
    path: "manual",
    canActivate: [AuthorizationGuard],
    component: ManualComponent,
  },
  {
    path: "unauthorized",
    canActivate: [UnauthorizedGuard],
    loadChildren: () => import("@bfl/components/unauthorized").then(m => m.BfcUnauthorizedModule.asChild(translations)),
  },
  {
    path: "access",
    canActivate: [AuthorizationGuard],
    component: AccessInfoComponent,
  },
  {
    path: "**",
    redirectTo: "",
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
