export const FR_TRANSLATIONS = {
  LOADING: "chargement du contenu...",  
  UNAUTHORIZED: {
    TITLE: "Pas d'autorisation",
    TEXT: "Vous n'êtes pas autorisé(e) à voir cette page.",
  },
  ACCESS_INFO: {
    TITLE: "Pas d'autorisation",
    TEXT: "Cette page n'est accessible qu'aux collaborateurs internes de BKW.",
  },
  ERROR: {
    FILE_NOT_FOUND:
            "Une erreur est survenue lors du chargement de la page. Veuillez réessayer plus tard.",
    UNAUTHORIZED: "Vous n'êtes pas autorisé(e) à voir cette page.",
    LOAD_IMAGES: "Certaines images n'ont pas pu être chargées.",
  },
};
