import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcGoogleAnalyticsService } from "@bfl/components/google-analytics";

@Injectable()
export class InitializerProvider {
  constructor(
    private bfcGoogleAnalyticsService: BfcGoogleAnalyticsService,
    private bfcConfigurationService: BfcConfigurationService,
  ) {}

  public initialize(): void {
    this.bfcGoogleAnalyticsService.initGoogleTagManager(
      this.bfcConfigurationService.configuration.googleTagManagerContainerId,
    );
    const globalNavElements = document.getElementsByTagName("bfe-global-navigation");
    if (globalNavElements.length !== 1) {
      throw new Error("too many or no global navigation element found");
    }
  }
}
