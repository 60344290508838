<ng-container *ngIf="loading">
    <bfe-loading-spinner></bfe-loading-spinner>
    <span class="loading-info"> {{ "LOADING" | bfcTranslate}}</span>
</ng-container>
    
<iframe 
    #iframe 
    class="manual-content"
    [srcdoc]="content | async" 
    (load)="iframeLoaded($event)"
></iframe>

