import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";
import { Observable } from "rxjs";
import { ManualService } from "./manual.service";

@Component({
  selector: "app-manual-component",
  templateUrl: "manual.component.html",
  styleUrls: ["manual.component.scss"],
})
export class ManualComponent implements OnInit {

  @ViewChild("iframe")
  iframe: HTMLIFrameElement;

  content: Observable<SafeHtml>;

  loading = true;

  private notifiedUserError = false;

  constructor(
    private bfcNotificationService: BfcNotificationService,
    private bfcTranslationService: BfcTranslationService,
    private manualService: ManualService,
  ) {}

  ngOnInit(): void {
    this.content = this.manualService.loadIframeContent();
  }

  iframeLoaded(event: Event){
    const iframeRef = event.target as HTMLIFrameElement;
    if (iframeRef.srcdoc !== ""){
      this.loading = false;
    }
  }

  @HostListener("window:message", ["$event"])
  onMessage(event: MessageEvent): void {
    if (!this.notifiedUserError && event.data && event.data === "error_loading_image") {
      this.notifiedUserError = true;
      this.bfcNotificationService.showNotification({
        type: BfcNotificationType.ERROR,
        message: this.bfcTranslationService.translate("ERROR.LOAD_IMAGES"),
      });
    }
  }
}
