import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { BfcAuthenticationService } from "@bfl/components/authentication";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Injectable()
export class AuthorizationGuard implements CanActivate {
  constructor(
    private bfcAuthenticationService: BfcAuthenticationService,
    private router: Router,
    private bfcConfigurationService: BfcConfigurationService,
  ) {}

  canActivate(): boolean {
    const userRole = this.bfcConfigurationService.configuration.userRole;
    if (this.bfcAuthenticationService.hasRealmRole(userRole)) {
      return true;
    }
    this.router.navigate(["unauthorized"]);
    return false;
  }
}
