import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from "@angular/core";
import { AuthorizationGuard } from "./auth/auth-guard.service";
import { UnauthorizedGuard } from "./auth/unauthorized-guard.service";
import { initalizerFactory } from "./initialization/initializer-factory";
import { InitializerProvider } from "./initialization/initializer-provider";
import { throwIfAlreadyLoaded } from "./module-import-guard";

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AuthorizationGuard,
    UnauthorizedGuard,
    InitializerProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: initalizerFactory,
      deps: [InitializerProvider],
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
