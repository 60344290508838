import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable()
export class ManualService {
  constructor(
    private httpClient: HttpClient,
    private domSanitizer: DomSanitizer,
    private bfcTranslationService: BfcTranslationService,
    private bfcConfigurationService: BfcConfigurationService,
    private bfcNotificationService: BfcNotificationService,
  ) {}

  loadIframeContent(): Observable<SafeHtml> {
    const apiUrl = this.bfcConfigurationService.configuration.apiUrl;
    const language = this.bfcTranslationService.language;
    const headers = { "Content-Type": "application/json" };
    return this.httpClient
      .get(`${apiUrl}/document?lang=${language}`, { headers, responseType: "text" })
      .pipe(
        catchError((error: unknown) => {
          const httpError = error as HttpErrorResponse;
          let message = "ERROR.FILE_NOT_FOUND";
          
          if (httpError && (httpError.status === 401 || httpError.status === 403)) {
            message = "ERROR.UNAUTHORIZED";
          }
      
          this.bfcNotificationService.showNotification({
            type: BfcNotificationType.ERROR,
            message: this.bfcTranslationService.translate(message),
          });
          return of("");
        }),
        map((content) => this.domSanitizer.bypassSecurityTrustHtml(content)),
      );
  }
}