export const DE_TRANSLATIONS = {
  LOADING: "Lade Inhalt...",  
  UNAUTHORIZED: {
    TITLE: "Keine Berechtigung",
    TEXT: "Sie haben keine Berechtigung, diese Seite zu sehen.",
  },
  ACCESS_INFO: {
    TITLE: "Keine Berechtigung",
    TEXT: "Diese Seite ist nur für interne Mitarbeiter der BKW zugänglich.",
  },
  ERROR: {
    FILE_NOT_FOUND:
            "Beim Laden der Seite ist ein Fehler aufgetreten. Versuchen Sie es bitte später erneut.",
    UNAUTHORIZED: "Sie sind nicht berechtigt, diese Seite zu sehen.",
    LOAD_IMAGES: "Einige Bilder konnten nicht geladen werden.",
  },
};
