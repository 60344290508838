import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  BfcAuthenticationInterceptor,
  BfcAuthenticationModule,
  BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
} from "@bfl/components/authentication";
import { BfcConfigurationModule, BFC_CONFIGURATIONS } from "@bfl/components/configuration";
import { BfcGoogleAnalyticsModule } from "@bfl/components/google-analytics";
import { BfcNotificationModule } from "@bfl/components/notification";
import { BfcSinglePageLayoutModule } from "@bfl/components/single-page-layout";
import { BfcUnauthorizedModule } from "@bfl/components/unauthorized";
import {
  BfcTranslationModule,
  BFC_TRANSLATIONS,
} from "@bfl/components/translation";
import { AccessInfoComponent } from "./access-info/access-info.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { authenticationInterceptorWhitelist } from "./config/authentication-interceptor-whitelist";
import { configuration } from "./config/configuration";
import { translations } from "./config/translations/translations";
import { CoreModule } from "./core/core.module";
import { ManualComponent } from "./manual/manual.component";
import { ManualService } from "./manual/manual.service";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    BfcTranslationModule,
    BfcGoogleAnalyticsModule,
    BfcConfigurationModule,
    BfcAuthenticationModule,
    CoreModule,
    AppRoutingModule,
    BfcSinglePageLayoutModule,
    HttpClientModule,
    BfcNotificationModule,
    BfcUnauthorizedModule,
  ],
  declarations: [AppComponent, ManualComponent, AccessInfoComponent],
  providers: [
    ManualService,
    HttpClient,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BfcAuthenticationInterceptor,
      multi: true,
    },
    {
      provide: BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
      useValue: authenticationInterceptorWhitelist,
    },
    {
      provide: BFC_TRANSLATIONS,
      useValue: translations,
      multi: true,
    },
    {
      provide: BFC_CONFIGURATIONS,
      useValue: configuration,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
